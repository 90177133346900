import Vue from 'vue'

import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'

/* Plugins */

import nuxt_plugin_plugin_44aa7c76 from 'nuxt_plugin_plugin_44aa7c76' // Source: ./vuetify/plugin.js (mode: 'all')
import nuxt_plugin_appplugin_2375e480 from 'nuxt_plugin_appplugin_2375e480' // Source: ../../../node_modules/@nuxt/bridge/dist/runtime/app.plugin.mjs (mode: 'all')
import nuxt_plugin_configplugin_1e0439cf from 'nuxt_plugin_configplugin_1e0439cf' // Source: ../../../node_modules/@nuxt/bridge/dist/runtime/config.plugin.mjs (mode: 'all')
import nuxt_plugin_nitrobridgeserver_2aa0bd28 from 'nuxt_plugin_nitrobridgeserver_2aa0bd28' // Source: ./nitro-bridge.server.mjs (mode: 'server')
import nuxt_plugin_nitrobridgeclient_cd8a3c18 from 'nuxt_plugin_nitrobridgeclient_cd8a3c18' // Source: ./nitro-bridge.client.mjs (mode: 'client')
import nuxt_plugin_plugin_47423903 from 'nuxt_plugin_plugin_47423903' // Source: ../../../node_modules/@pinia/nuxt/dist/runtime/plugin.vue2 (mode: 'all')
import nuxt_plugin_pluginutils_429f426d from 'nuxt_plugin_pluginutils_429f426d' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_644e2f10 from 'nuxt_plugin_pluginrouting_644e2f10' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_1f94d412 from 'nuxt_plugin_pluginmain_1f94d412' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_portalvue_7e62fb85 from 'nuxt_plugin_portalvue_7e62fb85' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_nuxtuseragent_751f0ad7 from 'nuxt_plugin_nuxtuseragent_751f0ad7' // Source: ./nuxt-user-agent.js (mode: 'all')
import nuxt_plugin_capiplugin_9050c776 from 'nuxt_plugin_capiplugin_9050c776' // Source: ./capi.plugin.mjs (mode: 'all')
import nuxt_plugin_errorpluginserver_2e6fe8e8 from 'nuxt_plugin_errorpluginserver_2e6fe8e8' // Source: ../../../node_modules/@nuxt/bridge/dist/runtime/error.plugin.server.mjs (mode: 'server')
import nuxt_plugin_piniaPersist_c76ef0ca from 'nuxt_plugin_piniaPersist_c76ef0ca' // Source: ../plugins/piniaPersist (mode: 'client')
import nuxt_plugin_axiosPlugin_641afe56 from 'nuxt_plugin_axiosPlugin_641afe56' // Source: ../plugins/axiosPlugin (mode: 'client')
import nuxt_plugin_evercamUi_af523e1e from 'nuxt_plugin_evercamUi_af523e1e' // Source: ../plugins/evercamUi (mode: 'client')
import nuxt_plugin_moment_36f1f0f4 from 'nuxt_plugin_moment_36f1f0f4' // Source: ../plugins/moment (mode: 'client')
import nuxt_plugin_notifications_3edb2b74 from 'nuxt_plugin_notifications_3edb2b74' // Source: ../plugins/notifications (mode: 'client')
import nuxt_plugin_confirmDialog_30c75298 from 'nuxt_plugin_confirmDialog_30c75298' // Source: ../plugins/confirmDialog (mode: 'client')
import nuxt_plugin_auth_92710cc8 from 'nuxt_plugin_auth_92710cc8' // Source: ../plugins/auth (mode: 'client')
import nuxt_plugin_errorTracker_c593a7f8 from 'nuxt_plugin_errorTracker_c593a7f8' // Source: ../plugins/errorTracker (mode: 'client')
import nuxt_plugin_axiosConfig_902ad638 from 'nuxt_plugin_axiosConfig_902ad638' // Source: ../plugins/axiosConfig (mode: 'client')
import nuxt_plugin_timepicker_5d65bae2 from 'nuxt_plugin_timepicker_5d65bae2' // Source: ../plugins/timepicker (mode: 'client')
import nuxt_plugin_veeValidate_36cfcf50 from 'nuxt_plugin_veeValidate_36cfcf50' // Source: ../plugins/veeValidate (mode: 'client')
import nuxt_plugin_shared_40c71b59 from 'nuxt_plugin_shared_40c71b59' // Source: ../plugins/shared (mode: 'client')
import nuxt_plugin_globalRefs_3df91d77 from 'nuxt_plugin_globalRefs_3df91d77' // Source: ../plugins/globalRefs (mode: 'client')
import nuxt_plugin_permissions_10b883e0 from 'nuxt_plugin_permissions_10b883e0' // Source: ../plugins/permissions (mode: 'client')
import nuxt_plugin_googleMaps_15a5e964 from 'nuxt_plugin_googleMaps_15a5e964' // Source: ../plugins/googleMaps (mode: 'client')
import nuxt_plugin_vueKonva_1774bfc3 from 'nuxt_plugin_vueKonva_1774bfc3' // Source: ../plugins/vueKonva (mode: 'client')
import nuxt_plugin_scssVars_41ad04f0 from 'nuxt_plugin_scssVars_41ad04f0' // Source: ../plugins/scssVars (mode: 'client')
import nuxt_plugin_device_270d8d4a from 'nuxt_plugin_device_270d8d4a' // Source: ../plugins/device (mode: 'client')
import nuxt_plugin_timeouts_48063db4 from 'nuxt_plugin_timeouts_48063db4' // Source: ../plugins/timeouts (mode: 'client')
import nuxt_plugin_eventListeners_5de407b9 from 'nuxt_plugin_eventListeners_5de407b9' // Source: ../plugins/eventListeners (mode: 'client')
import nuxt_plugin_units_465dfa1c from 'nuxt_plugin_units_465dfa1c' // Source: ../plugins/units.js (mode: 'client')
import nuxt_plugin_keycloak_75d5e2c5 from 'nuxt_plugin_keycloak_75d5e2c5' // Source: ../plugins/keycloak (mode: 'client')
import nuxt_plugin_imgproxy_2f48167f from 'nuxt_plugin_imgproxy_2f48167f' // Source: ../plugins/imgproxy (mode: 'client')
import nuxt_plugin_posthog_465a272c from 'nuxt_plugin_posthog_465a272c' // Source: ../plugins/posthog (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":true,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext, config = {}) {
  const store = null
  const router = await createRouter(ssrContext, config, { store })

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"titleTemplate":"%s","title":"Evercam Admin","script":[{"src":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002Fleader-line\u002Fleader-line.min.js"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"property":"og:policy","http-equiv":"Content-Security-Policy","content":"upgrade-insecure-requests"},{"hid":"description","name":"description","content":""}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Ffonts.googleapis.com\u002Fcss?family=Roboto:100,300,400,500,700,900&display=swap"},{"rel":"stylesheet","type":"text\u002Fcss","href":"https:\u002F\u002Fcdn.jsdelivr.net\u002Fnpm\u002F@mdi\u002Ffont@latest\u002Fcss\u002Fmaterialdesignicons.min.css"}],"style":[]},

    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      errPageReady: false,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        nuxt.errPageReady = false
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_44aa7c76 === 'function') {
    await nuxt_plugin_plugin_44aa7c76(app.context, inject)
  }

  if (typeof nuxt_plugin_appplugin_2375e480 === 'function') {
    await nuxt_plugin_appplugin_2375e480(app.context, inject)
  }

  if (typeof nuxt_plugin_configplugin_1e0439cf === 'function') {
    await nuxt_plugin_configplugin_1e0439cf(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_nitrobridgeserver_2aa0bd28 === 'function') {
    await nuxt_plugin_nitrobridgeserver_2aa0bd28(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nitrobridgeclient_cd8a3c18 === 'function') {
    await nuxt_plugin_nitrobridgeclient_cd8a3c18(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_47423903 === 'function') {
    await nuxt_plugin_plugin_47423903(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_429f426d === 'function') {
    await nuxt_plugin_pluginutils_429f426d(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_644e2f10 === 'function') {
    await nuxt_plugin_pluginrouting_644e2f10(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_1f94d412 === 'function') {
    await nuxt_plugin_pluginmain_1f94d412(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_7e62fb85 === 'function') {
    await nuxt_plugin_portalvue_7e62fb85(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtuseragent_751f0ad7 === 'function') {
    await nuxt_plugin_nuxtuseragent_751f0ad7(app.context, inject)
  }

  if (typeof nuxt_plugin_capiplugin_9050c776 === 'function') {
    await nuxt_plugin_capiplugin_9050c776(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_errorpluginserver_2e6fe8e8 === 'function') {
    await nuxt_plugin_errorpluginserver_2e6fe8e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_piniaPersist_c76ef0ca === 'function') {
    await nuxt_plugin_piniaPersist_c76ef0ca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axiosPlugin_641afe56 === 'function') {
    await nuxt_plugin_axiosPlugin_641afe56(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_evercamUi_af523e1e === 'function') {
    await nuxt_plugin_evercamUi_af523e1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_moment_36f1f0f4 === 'function') {
    await nuxt_plugin_moment_36f1f0f4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_notifications_3edb2b74 === 'function') {
    await nuxt_plugin_notifications_3edb2b74(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_confirmDialog_30c75298 === 'function') {
    await nuxt_plugin_confirmDialog_30c75298(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_auth_92710cc8 === 'function') {
    await nuxt_plugin_auth_92710cc8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_errorTracker_c593a7f8 === 'function') {
    await nuxt_plugin_errorTracker_c593a7f8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_axiosConfig_902ad638 === 'function') {
    await nuxt_plugin_axiosConfig_902ad638(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_timepicker_5d65bae2 === 'function') {
    await nuxt_plugin_timepicker_5d65bae2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_veeValidate_36cfcf50 === 'function') {
    await nuxt_plugin_veeValidate_36cfcf50(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_shared_40c71b59 === 'function') {
    await nuxt_plugin_shared_40c71b59(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_globalRefs_3df91d77 === 'function') {
    await nuxt_plugin_globalRefs_3df91d77(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_permissions_10b883e0 === 'function') {
    await nuxt_plugin_permissions_10b883e0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleMaps_15a5e964 === 'function') {
    await nuxt_plugin_googleMaps_15a5e964(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueKonva_1774bfc3 === 'function') {
    await nuxt_plugin_vueKonva_1774bfc3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_scssVars_41ad04f0 === 'function') {
    await nuxt_plugin_scssVars_41ad04f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_device_270d8d4a === 'function') {
    await nuxt_plugin_device_270d8d4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_timeouts_48063db4 === 'function') {
    await nuxt_plugin_timeouts_48063db4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_eventListeners_5de407b9 === 'function') {
    await nuxt_plugin_eventListeners_5de407b9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_units_465dfa1c === 'function') {
    await nuxt_plugin_units_465dfa1c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_keycloak_75d5e2c5 === 'function') {
    await nuxt_plugin_keycloak_75d5e2c5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_imgproxy_2f48167f === 'function') {
    await nuxt_plugin_imgproxy_2f48167f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_posthog_465a272c === 'function') {
    await nuxt_plugin_posthog_465a272c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    app,
    router
  }
}

export { createApp, NuxtError }
